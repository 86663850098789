import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, reaction, action, computed } from 'mobx';
import { observer } from 'mobx-react'
import moment from 'moment'
import styled from 'styled-components'
import { chunk, isEmpty } from 'lodash';

// components
// end components

// helpers
import { SERVER_DATE_FORMAT, SERVER_DATETIME_FORMAT, formatDuration } from 'helpers'
import { defaultFormatDuration } from 'helpers/duration'
// end helpers

// stores
import { User } from 'store/User'
import { Operator } from 'store/Operator'
import { WorkSlot } from 'store/WorkSlot'
import { WorkTimeStore } from 'store/WorkTime'
import { Table, Grid } from 'semantic-ui-react'
// end stores


export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const StyledTable = styled.table`
  background-color: #fff;
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d0d1d2;

  > thead {
    background-color: #f0f1f2;
    > th {
        padding: 0.25rem;
    }
  }
`

const TotalOvertime = styled.span`
  font-weight: bold;
  margin-right: 0.75em;
  color: #21ba45;
`

const TotalAbsence = styled.span`
  font-weight: bold;
  margin-right: 0.75em;
  color: #db2828;
`

const TimeBalance = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

function changeOffset(m, offset) {
    return m
        .clone()
        .utcOffset(offset)
        .add(m.utcOffset() - offset, 'm')
}

function getLeaveSlotFilter(scope) {
    return (leaveSlot) =>
        leaveSlot.startDate.clone().startOf('day').isBefore(scope.end) &&
        leaveSlot.endDate.clone().endOf('day').isAfter(scope.start)
}

function isOperatorUser(operator) {
    return operator && operator.user && !operator.user.isNew
}

export function getScopedLeaveSlots(scope, user, operator, leaveCalendar) {
    const target = user || operator || leaveCalendar
    let leaveSlots = target && target.leaveSlots

    // Check if operator is a user and data is stored at user level.
    if (isOperatorUser(operator) && operator.user.leaveSlots && operator.user.leaveSlots.length > 0) {
        leaveSlots = operator.user.leaveSlots
    }
    if (!leaveSlots) {
        return []
    }
    return leaveSlots.filter(getLeaveSlotFilter(scope))
}

export function getScopedLeaveCalendars(scope, user, operator) {
    const target = user || operator
    let leaveCalendars = target && target.leaveCalendars

    // Check if operator is a user and data is stored at user level.
    if (isOperatorUser(operator) && operator.user.leaveCalendars && operator.user.leaveCalendars.length > 0) {
        leaveCalendars = operator.user.leaveCalendars
    }
    if (!leaveCalendars) {
        return []
    }
    const scopedLeaveCalendars = []
    // eslint-disable-next-line
    for (const leaveCalendar of leaveCalendars.models) {
        const leaveSlots = leaveCalendar.leaveSlots.filter(getLeaveSlotFilter(scope))
        if (leaveSlots.length > 0) {
            scopedLeaveCalendars.push({ leaveCalendar, leaveSlots })
        }
    }
    return scopedLeaveCalendars
}

function getFlatLeaveSlots(scope, user, operator) {
    const flatLeaveSlots = []
    flatLeaveSlots.push(...getScopedLeaveSlots(scope, user, operator, null))
    // eslint-disable-next-line
    for (const { leaveSlots } of getScopedLeaveCalendars(scope, user, operator)) {
        flatLeaveSlots.push(...leaveSlots)
    }
    return flatLeaveSlots
}

function getScopedWorkSlots(scope, user, operator) {
    const target = user || operator
    let workSchedules = target && target.workSchedules

    // Check if operator is a user and data is stored at user level.
    if (isOperatorUser(operator) && operator.user.workSchedules && operator.user.workSchedules.length > 0) {
        workSchedules = operator.user.workSchedules
    }
    if (!workSchedules || workSchedules.length === 0) {
        return []
    }

    workSchedules = workSchedules.models
        .slice()
        .sort((l, r) => {
            if (l.startDate.isBefore(r.startDate)) {
                return -1
            } else if (l.startDate.isAfter(r.startDate)) {
                return 1
            } else {
                return 0
            }
        })

    let workSchedule = 0

    const scopedWorkSlots = []

    // Make sure to take schedule start date if scope start is before the schedule.
    const scopeStart = scope.start.isBefore(workSchedules[workSchedule].startDate) ? workSchedules[workSchedule].startDate.clone() : scope.start.clone()
    for (let day = scopeStart; day.isBefore(scope.end); day.add(1, 'd')) {
        while (workSchedule < workSchedules.length - 1 && day.isSameOrAfter(workSchedules[workSchedule + 1].startDate)) {
            workSchedule++
        }
        // eslint-disable-next-line
        for (const workSlot of workSchedules[workSchedule].workSlots.models) {
            if (workSlot[WorkSlot.DAYS[day.isoWeekday() - 1]]) {
                const start = day
                    .clone()
                    .hour(workSlot.startTime.hour())
                    .minute(workSlot.startTime.minute())
                    .second(workSlot.startTime.second())
                const end = day
                    .clone()
                    .hour(workSlot.endTime.hour())
                    .minute(workSlot.endTime.minute())
                    .second(workSlot.endTime.second())
                scopedWorkSlots.push({ start, end, isBreak: workSlot.isBreak })
            }
        }
    }
    return scopedWorkSlots
}

/**
 * Returns an unordered list of blocks to be rendered. Example:
 *
 * [
 *   {
 *     state: 'planned',
 *     start: ...,
 *     end: ...,
 *     overlays: {
 *       state: 'absent',
 *       start: ...,
 *       end: ...,
 *     }*
 *   },
 *   ...
 * ]
 */
export function getScopedWorkedSlots(scope, user, operator, workTimes, now) {
    // So to combine work slots and work times we first turn it into one
    // big array of events
    const events = []

    //eslint-disable-next-line
    for (const { start, end, isBreak } of getScopedWorkSlots(scope, user, operator)) {
        events.push({
            type: isBreak ? 'breakStart' : 'workSlotStart',
            time: start,
        })
        events.push({
            type: isBreak ? 'breakEnd' : 'workSlotEnd',
            time: end,
        })
        // If work times are not loaded we assume perfect attendance
        if (workTimes === null || workTimes.isLoading) {
            events.push({
                type: 'workTimeStart',
                time: start,
            })
            events.push({
                type: 'workTimeEnd',
                time: end,
                automaticallyClockedOut: false,
            })
        }
    }
    // eslint-disable-next-line
    for (const leaveslot of getFlatLeaveSlots(scope, user, operator)) {
        if (leaveslot.type !== 'exchange') {
            events.push({
                type: 'leaveSlotStart',
                time: leaveslot.startDate,
            })
            events.push({
                type: 'leaveSlotEnd',
                time: leaveslot.endDate,
            })
        }
    }

    if (workTimes !== null) {
        if (!workTimes.isLoading) {
            // eslint-disable-next-line
            for (let { startTime, endTime, automaticallyClockedOut } of workTimes.models) {
                // Normalize offset
                startTime = changeOffset(startTime, startTime.utcOffset())
                endTime = endTime && changeOffset(endTime, endTime.utcOffset())
                // Fit within scope
                startTime = startTime.isBefore(scope.start) ? scope.start : startTime
                endTime = endTime && (endTime.isAfter(scope.end) ? scope.end : endTime)
                // Add events
                events.push({ type: 'workTimeStart', time: startTime })
                if (endTime) {
                    events.push({ type: 'workTimeEnd', time: endTime, automaticallyClockedOut })
                }
            }
        }
        if (now.isBefore(scope.end)) {
            events.push({
                type: 'currentTime',
                time: now,
            })
        }
    }

    // We can now sort these events so that we can easily process them
    events.sort((l, r) => l.time.diff(r.time))

    // Now we create a list of slots based on these events
    const slots = []
    let slot = null

    let future = false
    let working = 0
    let workPlanned = 0
    let breakPlanned = 0
    let leave = 0
    let automaticClockOut = null

    // eslint-disable-next-line
    for (const { type, time, automaticallyClockedOut = false } of events) {
        switch (type) {
            case 'currentTime':
                future = true
                break
            case 'workSlotStart':
                workPlanned++
                break
            case 'workSlotEnd':
                workPlanned--
                break
            case 'breakStart':
                breakPlanned++
                break
            case 'breakEnd':
                breakPlanned--
                break
            case 'leaveSlotStart':
                leave++
                break
            case 'leaveSlotEnd':
                leave--
                break
            case 'workTimeStart':
                working++
                break
            case 'workTimeEnd':
                working--
                break
            default:
                console.warn('Unexpected event type:', type)
        }

        if (automaticallyClockedOut) {
            automaticClockOut = time
        } else if (automaticClockOut && !automaticClockOut.isSame(time)) {
            automaticClockOut = null
        }

        let state;
        if (leave > 0) {
            state = null;
        } else if (breakPlanned > 0) {
            state = 'break'
        } else if (workPlanned > 0) {
            if (working > 0 || future) {
                state = 'planned'
            } else {
                state = 'absent'
            }
        } else if (working > 0 && !future) {
            state = 'overtime'
        }

        if (slot && slot.state !== state) {
            if (!time.isSame(slot.start)) {
                slots.push({ ...slot, end: time, automaticallyClockedOut: automaticClockOut !== null })
            } else if (automaticallyClockedOut && slots.length > 0 && slots[slots.length - 1].end.isSame(time)) {
                slots[slots.length - 1].automaticallyClockedOut = true
            }
            slot = null
        }
        if (!slot && state) {
            slot = { state, start: time }
        }
    }

    if (slot && !scope.end.isSame(slot.start)) {
        slots.push({ ...slot, end: scope.end })
    }

    // Now we seperate these slots based on days
    const daySlots = []
    // eslint-disable-next-line
    for (let { state, start, end, automaticallyClockedOut } of slots) {
        let wrapTop = false
        while (true) {
            const endOfDay = start.clone().endOf('day')
            if (endOfDay.isBefore(end)) {
                daySlots.push({
                    state,
                    start,
                    end: endOfDay,
                    wrapTop,
                    wrapBottom: true,
                    automaticallyClockedOut: false,
                })

                wrapTop = true
                start = start.clone().startOf('day').add(1, 'day')
            } else {
                daySlots.push({
                    state,
                    start,
                    end,
                    wrapTop,
                    wrapBottom: false,
                    automaticallyClockedOut,
                })
                break
            }
        }
    }

    // Now we group absent slots into planned slots
    const groupedSlots = []
    // eslint-disable-next-line
    for (const slot of daySlots) {
        const groupState = slot.state === 'absent' ? 'planned' : slot.state

        if (
            groupedSlots.length > 0 &&
            groupedSlots[groupedSlots.length - 1].end.isSame(slot.start) &&
            groupedSlots[groupedSlots.length - 1].state === groupState
        ) {
            groupedSlots[groupedSlots.length - 1].end = slot.end
            groupedSlots[groupedSlots.length - 1].wrapBottom = slot.wrapBottom
        } else {
            const group = {
                ...slot,
                state: groupState,
                overlays: [],
                flatTop: false,
                flatBottom: false,
            }
            if (groupedSlots.length > 0 && groupedSlots[groupedSlots.length - 1].end.isSame(group.start)) {
                groupedSlots[groupedSlots.length - 1].flatBottom = true
                group.flatTop = true
            }
            groupedSlots.push(group)
        }

        const group = groupedSlots[groupedSlots.length - 1]
        if (slot.state !== groupState) {
            group.overlays.push({ state: slot.state, start: slot.start, end: slot.end })
        }
    }

    // Now we normalize start and end of overlays into numbers from 0 to 1
    // eslint-disable-next-line
    for (const { start, end, overlays } of groupedSlots) {
        if (overlays.length > 0) {
            const duration = end.diff(start)
            // eslint-disable-next-line
            for (const overlay of overlays) {
                overlay.startPos = overlay.start.diff(start) / duration
                overlay.endPos = overlay.end.diff(start) / duration
            }
        }
    }

    return groupedSlots
}

/**
 * Returns a list of blocks to be rendered. Example:
 *
 * [
 *   {
 *     state: 'planned',
 *     start: ...,
 *     end: ...,
 *     overlays: {
 *       state: 'absent',
 *       start: ...,
 *       end: ...,
 *     }*
 *   },
 *   ...
 * ]
 * @param scope
 * @param user
 * @param operator
 * @param workTimes
 * @param now
 * @param debug
 * @returns {*[]}
 */
function getScopedWorkedSlotsUntilTime(scope, user, operator, workTimes, now, debug) {
    const slots = []
    // eslint-disable-next-line
    for (const slot of getScopedWorkedSlots(scope, user, operator, workTimes, now)) {
        if (slot.start.isSameOrAfter(now)) {
            // Slot is after current time
            break // Slots are in order so we can stop
        }
        if (slot.end.isSameOrBefore(now)) {
            // Slot is fully contained
            slots.push(slot)
            continue
        }
        const newSlot = { ...slot, end: now }
        if (slot.overlays) {
            newSlot.overlays = []
            // eslint-disable-next-line
            for (const overlay of slot.overlays) {
                if (overlay.start.isSameOrAfter(now)) {
                    // Overlay is after current time
                    break // Overlays are in order so we can stop
                }
                if (overlay.end.isSameOrBefore(now)) {
                    // Overlay is fully contained
                    newSlot.overlays.push(overlay)
                    continue
                }
                newSlot.overlays.push({ ...overlay, end: now })
            }
        }
        slots.push(newSlot)
    }
    return slots
}

export function getTotalTimePlanned(scope, user, operator, workTimes, now) {
    let totalTimePlanned = 0

    // Extra check that workTimes are correctly filtered by the scope presented.
    const filteredWorkTimes = workTimes !== null ? {
        models: workTimes.filter(workTime => (workTime.endTime === null || workTime.endTime.isSameOrAfter(scope.start)) &&
            workTime.startTime.isSameOrBefore(scope.end)
        )
    } : workTimes

    // eslint-disable-next-line
    for (const { state, start, end } of getScopedWorkedSlotsUntilTime(scope, user, operator, filteredWorkTimes, now)) {
        if (state === 'planned') {
            totalTimePlanned += end.diff(start, 'm', true)
        }
    }

    return Math.round(totalTimePlanned)
}

/**
 *
 * Get the total time in minutes how much an user / operator worked.
 *
 * @param scope
 * @param user
 * @param operator
 * @param workTimes
 * @param now
 * @param debug
 * @returns {number}
 */
export function getTotalTimeActual(scope, user, operator, workTimes, now, debug ) {
    let totalTimeActual = 0

    // Extra check that workTimes are correctly filtered by the scope presented.
    const filteredWorkTimes = workTimes !== null ? {
        models: workTimes.filter(workTime => (workTime.endTime === null || workTime.endTime.isSameOrAfter(scope.start)) &&
            workTime.startTime.isSameOrBefore(scope.end)
        )
    } : workTimes

    // eslint-disable-next-line
    for (const { state, start, end, overlays } of getScopedWorkedSlotsUntilTime(scope, user, operator, filteredWorkTimes, now)) {
        if (state !== 'break') {
            totalTimeActual += end.diff(start, 'm', true)
        }

        if (state === 'planned') {
            // eslint-disable-next-line
            for (const { state, start, end } of overlays) {
                if (state === 'absent') {
                    totalTimeActual -= end.diff(start, 'm', true)
                }
            }
        }
    }

    return Math.round(totalTimeActual)
}

export function getExtraTimeBalance(year, user, operator) {
    const target = user || operator
    const extraTimeBalances = target && target.extraTimeBalances

    let balance = 0
    if (extraTimeBalances && extraTimeBalances.length > 0) {
        // eslint-disable-next-line
        for (const extraTime of extraTimeBalances.models) {
            if (extraTime.year !== year) {
                continue
            }
            if (extraTime.isAbsence) {
                balance -= extraTime.balance
            } else {
                balance += extraTime.balance
            }
        }
    }

    return Math.round(balance)
}

@observer
export default class WorkBalance extends Component {
    static propTypes = {
        scope: PropTypes.shape({ year: PropTypes.number.isRequired, week: PropTypes.number.isRequired }),
        scopeStart: PropTypes.instanceOf(moment).isRequired,
        scopeEnd: PropTypes.instanceOf(moment).isRequired,
        operator: PropTypes.instanceOf(Operator).isRequired,
        year: PropTypes.number.isRequired,
        workTimes: PropTypes.instanceOf(WorkTimeStore),
        user: PropTypes.instanceOf(User),
        week: PropTypes.number,
        month: PropTypes.number,
        inline: PropTypes.bool,
    }

    static defaultProps = {
        inline: false,
        workTimes: null,
        user: null,
    }

    constructor(...args) {
        super(...args)

        this.renderBalance = this.renderBalance.bind(this)
    }

    @observable now = moment()
    @observable _workTimes = null
    @observable yearScopeStart = null
    @observable yearScopeEnd = null

    componentDidMount() {
        const { user, operator, scope } = this.props

        this.scopeReaction = reaction(
            () => JSON.stringify([user?.id, operator?.id, scope.year, scope.week]),
            action(() => {
                const filterUserOrOperator = {}

                if (user) {
                    filterUserOrOperator['.user'] = user.id
                } else if (isOperatorUser(operator)) {
                    // Check if operator is a user and data is stored at user level.
                    filterUserOrOperator['.user'] = operator.user.id
                } else if (operator) {
                    filterUserOrOperator['.operator'] = operator.id
                }


                this._workTimes = null
                this.yearScopeStart = moment({ year: scope.year, month: 0, day: 1 }).startOf('day')
                this.yearScopeEnd = moment({ year: scope.year, month: 11, day: 31 }).endOf('day')

                this.now = moment()
                this._workTimes = new WorkTimeStore({
                    limit: false,
                    params: {
                        '.end_time:gte_or_isnull': this.yearScopeStart.format(SERVER_DATETIME_FORMAT),
                        '.start_time:lte': this.yearScopeEnd.format(SERVER_DATETIME_FORMAT),
                        ...filterUserOrOperator
                    },
                })
                this._workTimes.fetch().then(() => this.forceUpdate())
            }),
            { fireImmediately: true }
        )
    }

    componentWillUnmount() {
        this.scopeReaction()
    }

    @computed get workTimes() {
        const { workTimes } = this.props

        if (workTimes == null) {
            return this._workTimes
        }

        return workTimes
    }

    @computed get scopes() {
        let { scopeStart, year, week, month } = this.props
        if (week === undefined) {
            week = scopeStart.isoWeek()
        }
        if (month === undefined) {
            month = scopeStart.month()
        }
        return {
            'week': {
                start: moment().isoWeekYear(year).isoWeekday(1).isoWeek(week).startOf('day'),
                end: moment().isoWeekYear(year).isoWeekday(1).isoWeek(week).startOf('day').endOf('isoWeek'),
                label: `W${week}`,
            },
            'month': {
                start: moment({ year: year, month: month, day: 1 }).startOf('day'),
                end: moment({ year: year, month: month, day: 1 }).startOf('day').endOf('month'),
                label: MONTHS[month],
            },
            'year': {
                start: moment({ year: year, month: 0, day: 1 }).startOf('year'),
                end: moment({ year: year, month: 11, day: 31 }).endOf('year'),
                label: year,
            }
        }
    }

    renderBalance(key) {
        const { user, operator, year } = this.props
        const planned = getTotalTimePlanned(this.scopes[key], user, operator, this.workTimes, this.now)
        const actual = getTotalTimeActual(this.scopes[key], user, operator, this.workTimes, this.now)

        let balance = actual - planned

        // Also, add extra time balance of scope year
        if (key === 'year') {
            balance += getExtraTimeBalance(year, user, operator)
        }

        const Total = balance < 0 ? TotalAbsence : TotalOvertime

        return <Total>{formatDuration(balance, { unit: 'minute', maxUnit: 'hour' })}</Total>
    }

    render() {
        if (this.props.inline) {
            return (
                <>
                    {Object.keys(this.scopes).map((key) => (
                        <TimeBalance data-test-balance={key}>
                            <span>{this.scopes[key].label}</span>
                            {this.renderBalance(key)}
                        </TimeBalance>
                    ))}
                </>
            )
        }

        const { user, operator } = this.props

        /**
         * Retrieves the daily leave balance for either the user or the operator.
         *
         * As part of the technical implementation, either the user or the operator will have
         * `leaveBalanceDaily` defined. This distinction is abstracted since the primary focus is on accessing
         * the `leaveBalanceDaily` property, regardless of whether it belongs to the user or the operator.
         *
         * @returns {{}|*|null}
         */
        function leaveBalanceDaily() {
          if (user && !isEmpty(user.leaveBalanceDaily)) {
            return user.leaveBalanceDaily
          }

          if (operator && !isEmpty(operator.leaveBalanceDaily)) {
            return operator.leaveBalanceDaily
          }

          return null
        }

        const daysInMonth = this.scopes.month.start.daysInMonth()
        const balances = []
        const vacations = [leaveBalanceDaily()?.[0]?.vacation.legal.used]
        const rwhs = [leaveBalanceDaily()?.[0]?.rwh.used]
        const specials = [leaveBalanceDaily()?.[0]?.special.used]
        const daysChunks = chunk([...Array(daysInMonth).keys().map(k => k + 1)], 60)



        const start = this.scopes.year.start.clone()
        const end = this.scopes.month.start.clone()
        const plannedTillNow = getTotalTimePlanned({ start, end }, user, operator, this.workTimes, this.now)
        const actualTillNow = getTotalTimeActual({ start, end }, user, operator, this.workTimes, this.now)

        let balanceTillNow = actualTillNow - plannedTillNow

        const generateTable = days => (
          <Table compact="very" collapsing data-test-financial-view>
            <Table.Header>
              <Table.HeaderCell>Day</Table.HeaderCell>
              <Table.HeaderCell>Mutation</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Cum</Table.HeaderCell>

              <Table.HeaderCell>Vak mut</Table.HeaderCell>
              <Table.HeaderCell>Vak tot</Table.HeaderCell>
              <Table.HeaderCell>ADV mut</Table.HeaderCell>
              <Table.HeaderCell>ADV tot</Table.HeaderCell>
              <Table.HeaderCell>Speciaal mut</Table.HeaderCell>
              <Table.HeaderCell>Speciaal tot</Table.HeaderCell>
            </Table.Header>
              <Table.Body>
                {days.map((day) => {
                  const start = this.scopes.month.start.clone()
                  const end = this.scopes.month.start.clone().add({ days: day })

                  const planned = getTotalTimePlanned({ start, end }, user, operator, this.workTimes, this.now)
                  const actual = getTotalTimeActual({ start, end }, user, operator, this.workTimes, this.now)

                  const previousBalance = balances.at(-1)
                  let balance =  actual - planned
                  const mutation = balance - (previousBalance || 0)
                  balances.push(balance)

                  const previousVacationBalance = vacations.at(-1)
                  const mutationVacation = (previousVacationBalance || 0) - leaveBalanceDaily()?.[day]?.vacation.legal.used
                  vacations.push(leaveBalanceDaily()?.[day]?.vacation.legal.used)

                  const previousRwhBalance = rwhs.at(-1)
                  const mutationRwh = (previousRwhBalance || 0) - leaveBalanceDaily()?.[day]?.rwh.used
                  rwhs.push(leaveBalanceDaily()?.[day]?.rwh.used)

                  const previousSpecialBalance = specials.at(-1)
                  const mutationSpecial = (previousSpecialBalance || 0) - leaveBalanceDaily()?.[day]?.special.used
                  specials.push(leaveBalanceDaily()?.[day]?.special.used)

                  // Also, add extra time balance of scope year
                  // if (key === 'year') {
                  //     balance += getExtraTimeBalance(year, user, operator)
                  // }

                  // const Total = balance < 0 ? TotalAbsence : TotalOvertime

                  return (
                    <Table.Row data-test-date={end.clone().subtract({ days: 1 }).format(SERVER_DATE_FORMAT)} negative={mutation < 0}>
                      <Table.Cell collapsing>{day}</Table.Cell>
                      <Table.Cell collapsing data-test-mutation>{mutation ? formatDuration(mutation, { unit: 'minute', maxUnit: 'hour' }) : ''}</Table.Cell>
                      <Table.Cell collapsing data-test-total>{(balance !== previousBalance) || daysInMonth === parseInt(end.clone().subtract({ days: 1 }).format('DD')) ? formatDuration(balance, { unit: 'minute', maxUnit: 'hour' }) : ''}</Table.Cell>
                      <Table.Cell collapsing data-test-cumulative>{(balance !== previousBalance) || daysInMonth === parseInt(end.clone().subtract({ days: 1 }).format('DD')) ? formatDuration(balanceTillNow + balance, { unit: 'minute', maxUnit: 'hour' }) : ''}</Table.Cell>

                      <Table.Cell collapsing data-test-vacation-mutation>{mutationVacation ? defaultFormatDuration(mutationVacation) : ''}</Table.Cell>
                      <Table.Cell collapsing data-test-vacation-total>{day === 1 || mutationVacation || daysInMonth === parseInt(end.clone().subtract({ days: 1 }).format('DD')) ? defaultFormatDuration(leaveBalanceDaily()?.[day]?.vacation.legal.allowed + leaveBalanceDaily()?.[day]?.vacation.extra.allowed - leaveBalanceDaily()?.[day]?.vacation.legal.used - leaveBalanceDaily()?.[day]?.vacation.extra.used) : ''}</Table.Cell>

                      <Table.Cell collapsing data-test-rwh-mutation>{mutationRwh ? defaultFormatDuration(mutationRwh) : ''}</Table.Cell>
                      <Table.Cell collapsing data-test-rwh-total>{day === 1 || mutationRwh || daysInMonth === parseInt(end.clone().subtract({ days: 1 }).format('DD')) ? defaultFormatDuration(leaveBalanceDaily()?.[day]?.rwh.allowed - leaveBalanceDaily()?.[day]?.rwh.used) : ''}</Table.Cell>

                      <Table.Cell collapsing data-test-special-mutation>{mutationSpecial ? defaultFormatDuration(mutationSpecial) : ''}</Table.Cell>
                      <Table.Cell collapsing data-test-special-total>{day === 1 || mutationSpecial || daysInMonth === parseInt(end.clone().subtract({ days: 1 }).format('DD')) ? defaultFormatDuration(-leaveBalanceDaily()?.[day]?.special.used) : ''}</Table.Cell>

                      {/*<Table.Cell collapsing data-test-mutation>{defaultFormatDuration(mutationSpecial)}</Table.Cell>*/}
                      {/*<Table.Cell collapsing data-test-total>{defaultFormatDuration(user?.leaveBalanceDaily?.[day]?.special.allowed - user?.leaveBalanceDaily?.[day]?.special.used)}</Table.Cell>*/}
                    </Table.Row>
                  )
                })}
              </Table.Body>
          </Table>
        )

        return (
            <>
                <label>{t('workCalendar.timeBalance.title')}</label>
                <StyledTable>
                    <thead>
                        <tr>
                            {Object.keys(this.scopes).map((key) => <th>{this.scopes[key].label}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {Object.keys(this.scopes).map((key) => <td data-test-balance={key} >{this.renderBalance(key)}</td>)}
                        </tr>
                    </tbody>
                </StyledTable>
                <Grid columns="3">
                  <Grid.Row>
                    {[daysChunks.map(days => (
                        <Grid.Column stretched>{generateTable(days)}</Grid.Column>
                    ))]}
                  </Grid.Row>
                </Grid>
            </>
        )
    }
}
